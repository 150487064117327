import rehypeRaw from "rehype-raw";
import { For, Show } from "solid-js";
import SolidMarkdown from "solid-markdown";
import { rehypeUppercaseATags } from "~/utils/common";

export function ItemList({
  items,
}: {
  items: { title: string; description: string }[];
}) {
  return (
    <Show when={items.length > 0}>
      <div class="w-full">
        <For each={items}>
          {(item) => (
            <div class="pb-6">
              <div class="text-h4 text-textDark">
                <SolidMarkdown
                  class="markdown"
                  rehypePlugins={[rehypeRaw] as any}
                >
                  {item.title}
                </SolidMarkdown>
              </div>
              <div class="py-2 text-normal text-textNormal ">
                <SolidMarkdown
                  class="markdown"
                  rehypePlugins={[rehypeUppercaseATags, rehypeRaw] as any}
                >
                  {item.description}
                </SolidMarkdown>
              </div>
            </div>
          )}
        </For>
      </div>
    </Show>
  );
}
