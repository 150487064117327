"use server";

import { getRequestEvent } from "solid-js/web";
import { getStaticProxyJson } from "../static";
import { FootNote } from "../types/brand";
import { Metadata } from "../types/groups";

export type GiftCardBalanceRouteData = {
  isLoggedIn: boolean;
  staticContent: { metadata: Metadata; content: FootNote[] };
};

export const getGiftCardBalanceRouteData =
  async (): Promise<GiftCardBalanceRouteData> => {
    const staticContent = await getStaticProxyJson("gift-card-balance.json");

    return {
      isLoggedIn: getRequestEvent()?.locals.sid !== undefined,
      staticContent,
    };
  };
